<template>
  <div class="about">
    <div class="intro">
      <h1 v-html="$t('about.title')" />
      <img src="@/assets/images/denis.jpg" :alt="$t('about.image.denis')" srcset="@/assets/images/denis@2x.jpg 2x">
    </div>

    <div class="personal">
      <p class="chapeau">{{ $t('about.personal.chapeau') }}</p>
      <h3 v-html="$t('about.personal.content')" />
    </div>
    
    <div class="competence">
      <p class="chapeau">{{ $t('about.competence.chapeau') }}</p>
      <div class="grid">
        <div class="cell">
          <h4 v-html="$t('about.competence.designCompetence')" />
          <table>
            <tbody>
              <tr>
                <td>{{ $t('about.competence.webDesign') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.mobileFirstDesign') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.designSystems') }}</td>
                <td>+</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.responsiveDesign') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.uxDesign') }}</td>
                <td>+</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.uiDesign') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.emailDesign') }}</td>
                <td>+</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.appDesign') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.adBanner') }}</td>
                <td>±</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.graphicDesign') }}</td>
                <td>+</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.logoDesign') }}</td>
                <td>±</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.presenting') }}</td>
                <td>+</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.sessions') }}</td>
                <td>+</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="cell">
          <h4 v-html="$t('about.competence.designTools')" />
          <table>
            <tbody>
              <tr>
                <td>Sketch</td>
                <td>++</td>
              </tr>
              <tr>
                <td>Adobe XD</td>
                <td>++</td>
              </tr>
              <tr>
                <td>Adobe Photoshop</td>
                <td>++</td>
              </tr>
              <tr>
                <td>Adobe Illustrator</td>
                <td>+</td>
              </tr>
              <tr>
                <td>Adobe InDesign</td>
                <td>++</td>
              </tr>
              <tr>
                <td>Adobe After Effects</td>
                <td>+</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="cell cell--with-icons">
          <h4 v-html="$t('about.competence.frontEnd')" />
          <table>
            <tbody>
              <tr>
                <td><i class="fab fa-fw fa-html5"></i></td>
                <td>HTML</td>
                <td>++</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-css3"></i></td>
                <td>CSS</td>
                <td>++</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-sass"></i></td>
                <td>SASS</td>
                <td>++</td>
              </tr>
              <tr>
                <td><i class="fa fa-fw fa-atom"></i></td>
                <td>BEM</td>
                <td>++</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-js"></i></td>
                <td>JS</td>
                <td>+</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-php"></i></td>
                <td>PHP</td>
                <td>±</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-vuejs"></i></td>
                <td>Vue.js</td>
                <td>+</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-laravel"></i></td>
                <td>Blade</td>
                <td>++</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-git-alt"></i></td>
                <td>GIT</td>
                <td>+</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-npm"></i></td>
                <td>NPM</td>
                <td>+</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-gulp"></i></td>
                <td>Gulp</td>
                <td>+</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-wordpress"></i></td>
                <td>Wordpress</td>
                <td>±</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-jira"></i></td>
                <td>Jira</td>
                <td>++</td>
              </tr>
              <tr>
                <td><i class="fab fa-fw fa-confluence"></i></td>
                <td>Confluence</td>
                <td>+</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="cell">
          <h4 v-html="$t('about.competence.other')" />
          <table>
            <tbody>
              <tr>
                <td>Scrum <small>{{ $t('about.competence.method') }}</small></td>
                <td>++</td>
              </tr>
              <tr>
                <td>Scrum master <small>{{ $t('about.competence.role') }}</small></td>
                <td>++</td>
              </tr>
              <tr>
                <td>Kanban <small>{{ $t('about.competence.method') }}</small></td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.continuousLearning') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.collaborating') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.quality') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.dutch') }} <small>{{ $t('about.competence.native')}}</small></td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.english') }}</td>
                <td>++</td>
              </tr>
              <tr>
                <td>{{ $t('about.competence.driversLicence') }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      
    </div>
    
    <div class="clients">
      <p class="chapeau">{{ $t('about.clients.chapeau') }}</p>
      <div class="grid">
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-vdvalk.svg')" title="Van der Valk" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-hbn.svg')" title="HBN Law & Tax" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-sunnycars.svg')" title="Sunny Cars" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-ns.svg')" title="NS" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-bisolvon.svg')" title="Bisolvon" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-ff.svg')" title="Fast & Fluid" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-fpg.svg')" title="Futureproof Group" />
        </div>
        <div class="cell">
          <inline-svg :src="require('@/assets/images/logos/logo-allunited.svg')" title="All United" />
        </div>
      </div>
    </div>

    <div class="work">
      <p class="chapeau">{{ $t('about.work.chapeau') }}</p>
      <ul class="list">
        <li class="entry">
          <div class="entry__period">2011 – 2020</div>
          <div class="entry__title">{{ $t('about.work.az.title') }} – {{ $t('about.work.az.employer') }}</div>
          <!-- <div class="entry__meta">{{ $t('about.work.az.employer') }}</div> -->
          <div class="entry__description">
            <ul>
              <li v-for="$n in $t('about.work.az.descriptions').length" :key="$n">
                {{ $t('about.work.az.descriptions')[$n-1] }}
              </li>
            </ul>
          </div>
        </li>
        <li class="entry">
          <div class="entry__period">2010 – 2012</div>
          <div class="entry__title">{{ $t('about.work.oict.title') }} – {{ $t('about.work.oict.employer') }}</div>
          <!-- <div class="entry__meta">{{ $t('about.work.oict.employer') }}</div> -->
          <div class="entry__description">
            <ul>
              <li v-for="$n in $t('about.work.oict.descriptions').length" :key="$n">
                {{ $t('about.work.oict.descriptions')[$n-1] }}
              </li>
            </ul>
          </div>
        </li>
        <li class="entry">
          <div class="entry__period">2008 – 2011</div>
          <div class="entry__title">{{ $t('about.work.sduwd.title') }} – {{ $t('about.work.sduwd.employer') }}</div>
          <!-- <div class="entry__meta">{{ $t('about.work.sduwd.employer') }}</div> -->
          <div class="entry__description">
            <ul>
              <li v-for="$n in $t('about.work.sduwd.descriptions').length" :key="$n">
                {{ $t('about.work.sduwd.descriptions')[$n-1] }}
              </li>
            </ul>
          </div>
        </li>
        <li class="entry">
          <div class="entry__period">2008 – 2011</div>
          <div class="entry__title">{{ $t('about.work.republic.title') }} – {{ $t('about.work.republic.employer') }}</div>
          <!-- <div class="entry__meta">{{ $t('about.work.republic.employer') }}</div> -->
          <div class="entry__description">
            <ul>
              <li v-for="$n in $t('about.work.republic.descriptions').length" :key="$n">
                {{ $t('about.work.republic.descriptions')[$n-1] }}
              </li>
            </ul>
          </div>
        </li>
        <li class="entry">
          <div class="entry__period">2002 – 2009</div>
          <div class="entry__title">{{ $t('about.work.stcrt.title') }} – {{ $t('about.work.stcrt.employer') }}</div>
          <!-- <div class="entry__meta"></div> -->
          <div class="entry__description">
            <ul>
              <li v-for="$n in $t('about.work.stcrt.descriptions').length" :key="$n">
                {{ $t('about.work.stcrt.descriptions')[$n-1] }}
                </li>
            </ul>
          </div>
        </li>
        <li class="entry">
          <div class="entry__period">1997 – 2001</div>
          <div class="entry__title">{{ $t('about.work.neroc.title') }} – {{ $t('about.work.neroc.employer') }}</div>
          <!-- <div class="entry__meta"></div> -->
          <div class="entry__description">
            <ul>
              <li v-for="$n in $t('about.work.neroc.descriptions').length" :key="$n">
                {{ $t('about.work.neroc.descriptions')[$n-1] }}
                </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <div class="education">
      <p class="chapeau">{{ $t('about.education.chapeau') }}</p>
      <ul class="list">
        <li class="entry">
          <div class="entry__period">1995 – 1999</div>
          <div class="entry__title">{{ $t('about.education.glr.course') }}</div>
          <div class="entry__meta">{{ $t('about.education.glr.school') }}</div>
        </li>
        <li class="entry">
          <div class="entry__period">1993 – 1995</div>
          <div class="entry__title">{{ $t('about.education.kc.course') }}</div>
          <div class="entry__meta">{{ $t('about.education.kc.school') }}</div>
        </li>
        <li class="entry">
          <div class="entry__period">1991 – 1993</div>
          <div class="entry__title">{{ $t('about.education.eg.course') }}</div>
          <div class="entry__meta">{{ $t('about.education.eg.school') }}</div>
        </li>
      </ul>
    </div>
    
  </div>
</template>

<script>


export default {

}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_About.scss';
</style>